<template>
   <div class="wrapper">
<!--       商城分类页面-->
     <headBack>
       <template v-slot:title>
         <div class='title'>
             全部分类
         </div>
       </template>
     </headBack>
     <div class='header_cont'></div>
      <div class="main_box">
        <div class="search_box">
          <div class="search_main">
            <img src="https://static.yihu365.cn/img/h5Img/shop/search.png" alt="">
            <input type="text" placeholder="请输入商品名称" @focus="toSearch">
            <span class="">搜索</span>
          </div>
        </div>
           <div class="f_box">
             <van-sidebar v-model="active" @change="changeItem">
               <van-sidebar-item :title="item.name" v-for="(item,index) in typeList" />

             </van-sidebar>
             <div class="lists">
                 <div class="item" v-for="(item,index) in mallList" >
                   <img class="photo" :src="item.iconUrl" alt="" @click="toDetail(item)">
                    <div class="info">
                       <div class="title" @click="toDetail(item)">{{item.name}}</div>
                       <div class="p_box">
                           <div class="price" @click="toDetail(item)">¥<span>{{item.price}}</span></div>
                           <div class="tb" @click="addCart(item)"><img src="https://static.yihu365.cn/img/h5Img/shop/add_cart.png" alt=""></div>

                       </div>
                    </div>
                 </div>
             </div>
           </div>
      </div>
     <div class="cart_box animate__animated animate__fadeInBottomRight">
       <van-badge :content="cartInfo.goodsSum" @click="toCart">
         <div class="cart">
           <img src="https://static.yihu365.cn/img/h5Img/shop/cart_4.png" alt="">
         </div>

       </van-badge>
     </div>
   </div>
</template>

<script>
import {setWxShareDate} from '@/libs/utils.js'
import {onMounted, ref, computed, reactive} from "vue"
import {useRouter, useRoute} from 'vue-router'
import {useStore} from 'vuex'
import {Dialog,Toast} from 'vant'
import shopApi from '@axios/shop'
export default {
  name: "classify",
  setup(){
     const active = ref('0')
     const store = useStore();
     const route = useRoute()
     const router = useRouter()


    active.value = route.query.index
    const typeList = ref("");
    const getTypeList = () => {
       const params = {
           type:2,
           typeId : 401
       }
       shopApi.getTypeList(params).then((res)=> {
         typeList.value = res.data
         changeItem(active.value)
       })

    }
    getTypeList()

    const mallList = ref('');
    const getMallList =(id) => { // 商品列表
       const params ={
         typeId : id,
       }
      shopApi.getMallList(params).then((res)=> {
        console.log(res)
        mallList.value = res.data.pageData
      })
    }
    const changeItem =(index) => {
         const typeId = typeList.value[index].typeId;
          getMallList(typeId)
    }

    //cart  add

    const addCart = (item) => {
      const params = {
        userId : store.state.uid,
        synchronization:[{
          goodsId : item.id,
          goodsNum : 1,
          type : 3 ,
          unitPrice : item.price,
          goodsName: item.name,
          iconUrl : item.iconUrl,
        }]

      }
      shopApi. setSynchronization(params).then((res)=> {
        console.log('Addcart',res)
        cartInfo.value = res.data;
        Toast('已加入购物车')
        getCartNums()

      })
    }
    // Cart  nums
    const cartInfo = ref('')
    const getCartNums = () => {
      const params = {
        userId : store.state.uid
      }
      shopApi.getCartInfos(params).then((res)=> {
        console.log('cart',res)
        cartInfo.value = res.data;
      })
    }
    getCartNums()
    //跳转
    const  toCart = () => { //          购物车
      router.push({
        name: 'cart',
        query: {

        }
      })
    }

     const toDetail=(item)=> {
       router.push({
         name: 'shopDetail',
         query: {
           goodsId : item.id
         }
       })
     }
    const toSearch =()=> {
      router.push({
        name:'searchShop'
      })
    }
    return {
      active,
      typeList,
      changeItem,
      cartInfo,
      mallList,
      toCart,
      addCart,
      toDetail,
      toSearch
    }
  }
}
</script>

<style scoped lang="scss">
.search_box{
  flex-grow: 1;
  margin:30px 20px 30px;
  .search_main{
    box-sizing: border-box;
    padding:4px;
    display: flex;
    align-items: center;
    border:3px solid #00c291;
    border-radius: 60px;
    justify-content: space-between;
    img{
      width: 36px;
      height: 36px;
      margin-left: 8px;
      margin-right: 8px;
    }
    input{
      color:#666;
      caret-color: #999999;
      font-size: 28px;
      box-sizing: border-box;
      padding-left: 4px;
      border:none;
      height:40px;
      flex-grow: 1;
    }
    ::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      font-size: 28px;
      color:#999;
    }
    :-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      font-size: 28px;
      color:#999;
    }
    ::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      font-size: 28px;
      color:#999;
    }
    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      font-size: 28px;
      color:#999;
    }

    span{
      margin-left: 10px;
      display: block;
      text-align: center;
      white-space: nowrap;
      background: #00c291;
      color:#FFFFFF;
      font-size: 30px;

      box-sizing: border-box;
      padding:10px 20px;
      border-radius: 40px;
    }
  }
}

   .van-sidebar{
     background: #f7f8fa;
     .van-sidebar-item{
       line-height: 20px;
       white-space: nowrap;
     }
     .van-sidebar-item--select::before{
       width: 0;
     }
   }
   .f_box{
     width: 100%;
     position: absolute;
     top:215px;
     left: 0;
     bottom:0;
     display: flex;
     box-sizing: border-box;
     ::v-deep(.van-sidebar-item--select){
       color:#00c291;
     }
     .lists{
       ::v-deep(.van-sidebar-item){
         font-size: 32px;
       }
       width:200px;
       margin-left: 10px;
       flex-grow: 1;
       overflow: scroll;
       //border:1px solid red;
       .item{
          display: flex;
         box-sizing: border-box;
         padding:0px 20px 20px 6px;
         //border-bottom: 1px solid #e5e5e5;
         .photo{
           width: 160px;
           height: 160px;
           margin-right: 10px;
         }
         .info{
           flex-grow: 1;
           font-size: 26px;
           display: flex;
           flex-direction: column;
           justify-content: space-between;
             .title{
               text-overflow: ellipsis;
               font-size: 30px;
               flex-grow: 1;
               display: -webkit-box;
               -webkit-line-clamp: 2;
               -webkit-box-orient: vertical;

               display: -moz-box;
               -moz-line-clamp: 2;
               -moz-box-orient: vertical;

               overflow-wrap: break-word;
               word-break: break-all;
               white-space: normal;
               overflow: hidden;

             }
           .price{
              font-size: 30px;
              color:#e4393c;
              span{
                 font-size: 36px;
              }
           }
         }
         .p_box{
           display: flex;
           justify-content: space-between;
           .price{
             flex-grow: 1;
           }
           .tb{
              img{
                width: 45px;
                height: 45px;
                margin-right: 20px;
              }
           }
         }
       }
     }
   }


.cart_box{
  position: fixed;
  right: 80px;
  bottom:80px;
  z-index: 1;
  ::v-deep(.van-badge){
    background: #00c291;
    border-color:#00c291;
  }
  ::v-deep(.van-badge--fixed){
    top:20px;
    right:16px;
  }
}
.cart{
  img{
    margin-top: 15px;
    width: 72px;
    height: 72px;

  }
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #FFFFFF;
  box-shadow:0 0 400px 1px #00c291;
  text-align: center;

  .num{
    font-style: normal;
    font-size: 24px;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: #FF4A26;
    color:#fff;
    text-align: center;
    line-height: 36px;
    position: absolute;
    right:14px;
    top:14px;
    z-index: 2;
  }
}
</style>
